import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post, get, patch } from '../../utils/fetch'; // Helper for HTTP requests

// 1. Categories APIs
export const addCategory = createAsyncThunk(
  'expense/addCategory',
  ({ payload }) => new Promise((resolve, reject) => resolve(post(`expense/categories`, payload))),
);

export const getCategories = createAsyncThunk(
  'expense/getCategories',
  () => new Promise((resolve, reject) => resolve(get(`expense/categories`))),
);

// 2. Expenses APIs
export const addExpense = createAsyncThunk(
  'expense/addExpense',
  ({ payload }) => new Promise((resolve, reject) => resolve(post(`expense/add`, payload))),
);

export const getAllExpenses = createAsyncThunk(
  'expense/getAllExpenses',
  ({ month, year }) =>
    new Promise((resolve, reject) => {
      const queryParams = [];
      if (month) queryParams.push(`month=${month}`);
      if (year) queryParams.push(`year=${year}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      resolve(get(`expense/get-all${queryString}`));
    }),
);

export const updateExpense = createAsyncThunk(
  'expense/updateExpense',
  ({ id, payload }) => new Promise((resolve, reject) => resolve(patch(`expense/${id}`, payload))),
);

// 3. Opening Balance APIs
export const setOpeningBalance = createAsyncThunk(
  'expense/setOpeningBalance',
  ({ payload }) =>
    new Promise((resolve, reject) => resolve(post(`expense/opening-balance`, payload))),
);
export const getOpeningBalance = createAsyncThunk(
  'expense/getOpeningBalnces',
  ({ month, year }) =>
    new Promise((resolve, reject) => {
      const queryParams = [];
      if (month) queryParams.push(`month=${month}`);
      if (year) queryParams.push(`year=${year}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      resolve(get(`expense/opening-balance${queryString}`));
    }),
);

export const getReports = createAsyncThunk(
  'expense/getReports',
  ({ month, year }) =>
    new Promise((resolve, reject) => {
      const queryParams = [];
      if (month) queryParams.push(`month=${month}`);
      if (year) queryParams.push(`year=${year}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      resolve(get(`expense/reports/get-all${queryString}`));
    }),
);

export const getMonthlySummary = createAsyncThunk(
  'expense/getMonthlySummary',
  ({ year }) =>
    new Promise((resolve, reject) => {
      const queryParams = [];
      if (year) queryParams.push(`year=${year}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      resolve(get(`expense/get-monthly-summary${queryString}`));
    }),
);
